<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="vertical">
          <a-row :gutter="48">
            <a-col
              :md="6"
              :sm="24"
            >
              <a-form-item
                label="公司名称"
                prop="name"
              >
                <a-input
                  v-model="queryParam.name"
                  placeholder="请输入公司名称"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col
              :md="6"
              :sm="24"
            >
              <a-form-item
                label="状态"
                prop="status"
              >
                <a-select
                  placeholder="请选择"
                  v-model="queryParam.status"
                  style="width: 100%"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  allow-clear
                >
                  <a-select-option
                    v-for="(d, index) in status"
                    :key="index"
                    :value="d.id"
                  >{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col
              :md="!advanced && 8 || 24"
              :sm="24"
            >
              <span
                class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}"
              >
                <a-button
                  type="primary"
                  @click="handleQuery"
                ><a-icon type="search" />查询</a-button>
                <a-button
                  style="margin-left: 8px;margin-top:28px;"
                  @click="resetQuery"
                ><a-icon type="redo" />重置</a-button>
                <a-button
                  type="primary"
                  @click="$refs.createForm.handleAdd()"
                  v-hasPermi="['gx:devicetype:add']"
                  style="margin-left: 8px;margin-top:28px;"
                >
                  <a-icon type="plus" />新增
                </a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <!-- <div class="table-operations">
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['tl:devicemodel:edit']"
        >
          <a-icon type="edit" />修改
        </a-button>
        <a-button
          type="danger"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['tl:devicemodel:remove']"
        >
          <a-icon type="delete" />删除
        </a-button>

        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div> -->
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <CreateInfo ref="CreateInfo"></CreateInfo>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span
          slot="createTime"
          slot-scope="text, record"
        >
          {{ parseTime(record.createTime) }}
        </span>
        <span
          slot="provinceCode"
          slot-scope="text, record"
        >
          {{ record.provinceCode }} {{ record.cityCode }} {{ record.areaCode }}
        </span>
        <span
          slot="status"
          slot-scope="text, record"
        >
          {{ record.status ? '启用' : '禁用' }}
        </span>
        <span
          slot="userNum"
          slot-scope="text, record"
        >

          <a @click="$refs.CreateInfo.handleAdd(record.id, 1)">
            {{ record.userNum ? record.userNum : 0 }}
          </a>
        </span>
        <span
          slot="devNum"
          slot-scope="text, record"
        >
          <a @click="$refs.CreateInfo.handleAdd(record.id, 2)">
            {{ record.devNum ? record.devNum : 0 }}
          </a>
        </span>
        <span
          slot="operation"
          slot-scope="text, record"
        >
          <!-- <a-divider type="vertical" v-hasPermi="['tl:devicemodel:edit']" /> -->
          <a
            @click="$refs.createForm.handleUpdate(record, undefined)"
            v-hasPermi="['gx:devicetype:edit']"
          >
            <a-icon type="edit" />修改
          </a>
          <a-divider
            type="vertical"
            v-hasPermi="['gx:devicetype:remove']"
          />
          <a
            @click="handleDelete(record)"
            v-hasPermi="['gx:devicetype:remove']"
          >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageCompany, delCompany } from '@/api/project/company'
import CreateForm from './modules/CreateForm'
import CreateInfo from './modules/CreateInfo'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'DeviceType',
  components: {
    CreateForm,
    CreateInfo
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      status: [{ id: 1, name: '正常' }, { id: 0, name: '禁用' }],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        name: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '公司名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '省市区',
          dataIndex: 'provinceCode',
          scopedSlots: { customRender: 'provinceCode' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '员工数',
          dataIndex: 'userNum',
          scopedSlots: { customRender: 'userNum' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备数',
          dataIndex: 'devNum',
          scopedSlots: { customRender: 'devNum' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    const name = this.$route.params.tenantName
    if (name) {
      this.queryParam.name = name
    }
    this.getList()
    console.log(this.$route, 'ceshi luy ')
  },
  computed: {
  },
  watch: {
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    /** 查询公司信息列表 */
    getList () {
      this.loading = true
      pageCompany(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        sortField: 'createTime',
        sortOrder: 'DESCEND',
        name: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        // content: '确认删除所选中数据?',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delCompany(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel () { }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('valve/project/export', {
            ...that.queryParam
          }, `型号信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () { }
      })
    }
  }
}
</script>
